import React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { FlexRow } from "../components/styles"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark
  return (
    <Layout>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <div className="blog-posts">
        {posts
          .filter(post => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
            return (
              <FlexRow
                style={{ justifyContent: "space-between" }}
                key={post.id}
              >
                <p>
                  <Link to={post.frontmatter.path}>
                    {post.frontmatter.title}
                  </Link>
                </p>
                <p>{post.frontmatter.date}</p>
              </FlexRow>
            )
          })}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY.MM.DD")
            path
          }
        }
      }
    }
  }
`

export default IndexPage
